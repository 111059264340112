import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, Paper } from '@mui/material';

const TransactionHistory = ({ userID }) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/transaction-history`, { userID });
        // Extract data from response
        const fetchedTransactions = response.data;

        // Sort transactions first by status, putting 'Available' at the top
        // and then by the date in descending order within each status category
        const sortedTransactions = fetchedTransactions.sort((a, b) => {
          if (a.status === 'available' && b.status !== 'available') return -1;
          if (a.status !== 'available' && b.status === 'available') return 1;
          // For items with the same status, sort by date in descending order
          return new Date(b.transactionDate) - new Date(a.transactionDate);
        });

        setTransactions(sortedTransactions);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, [userID]);

  // Helper function to format the date (show only the date part)
  const formatDate = (isoDateString) => {
    return new Date(isoDateString).toLocaleDateString(); // Only show date, not time
  };

  // Helper function to render status with color
  const renderStatusChip = (status) => {
    if (status === 'available') {
      return <Chip label="Available" sx={{ backgroundColor: '#d0f0c0', color: '#4CAF50', fontWeight: 'bold' }} />;
    } else if (status === 'expired') {
      return <Chip label="Expired" sx={{ backgroundColor: '#f8d7da', color: '#f44336', fontWeight: 'bold' }} />;
    } else {
      return <Chip label={status} sx={{ backgroundColor: '#e0e0e0', color: '#555' }} />;
    }
  };

  return (
    <Box sx={{ padding: 4, display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '100%', maxWidth: '900px' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 3, color: '#333', textAlign: 'center' }}>
          Credit History
        </Typography>
        
        <TableContainer component={Paper} sx={{ borderRadius: '12px' }}>
          <Table>
            <TableHead sx={{ backgroundColor: '#f4f4f4' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>Received On</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>Type</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>Credit Balance</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>Expiry</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.length > 0 ? (
                transactions.map((transaction, index) => (
                  <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#fafafa' } }}>
                    <TableCell>{formatDate(transaction.transactionDate)}</TableCell>
                    <TableCell>{transaction.transactionType}</TableCell>
                    <TableCell>${transaction.creditBalance?.toFixed(2) || '0.00'} / ${transaction.amount?.toFixed(2)}</TableCell>
                    <TableCell>{renderStatusChip(transaction.status)}</TableCell>
                    <TableCell>{transaction.expiryDate ? formatDate(transaction.expiryDate) : 'N/A'}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} sx={{ textAlign: 'center', color: '#888' }}>
                    No transactions available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TransactionHistory;
