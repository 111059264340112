import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { flushSync } from 'react-dom'; // Import flushSync to force immediate updates
import { Box, Typography, Paper, TextField, IconButton, Tooltip, Skeleton, CircularProgress, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import Markdown from 'markdown-to-jsx';
import hljs from 'highlight.js';
import 'highlight.js/styles/github-dark.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import Lottie from 'react-lottie';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import animationData from '../lottie/loading2.json';
import { getOpenAIResponse } from '../services/openAIService';
import { getGeminiResponse } from '../services/geminiService';
import { db } from '../firebase';
import { collection, doc, getDoc, setDoc, updateDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';

const Logo = process.env.PUBLIC_URL + '/logo.png';

const Chat = ({ selectedProvider, selectedModel, userID, userName }) => {
  const { conversationID } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAIResponse, setLoadingAIResponse] = useState(false);
  const [copied, setCopied] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const inputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  const [availableCredits, setAvailableCredits] = useState(0);
  const [isCreditsLoading, setIsCreditsLoading] = useState(true);
  const [trialStatus, setTrialStatus] = useState('completed'); // Add trial status state
  const [trialUsage, setTrialUsage] = useState(0); // Add trial usage state

  useEffect(() => {
    const fetchOrCreateUser = async () => {
      try {
        // Attempt to fetch trial details for the user
        const userDocRef = doc(db, 'users', userID);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          // If the user exists, use the helper to fetch trial details and update state
          await fetchTrialDetailsAndUpdate();
        } else {
          // If no user document exists, create a new one and initialize trial
          await setDoc(userDocRef, {
            userID,
            userName,
            trialStatus: 'active',
            trialUsage: 0,
          });
          setTrialStatus('active');
          setTrialUsage(0);
          console.log('New user created with active trial status');
        }
      } catch (error) {
        console.error("Error fetching or creating user data:", error);
      }
    };
  
    fetchOrCreateUser(); // Fetch or create user trial data on component mount
  }, [userID, userName]);
  
  
  useEffect(() => {
    const fetchConversation = async () => {
      setLoading(true);
      const conversationRef = doc(db, 'conversations', conversationID);
      const docSnap = await getDoc(conversationRef);
      if (docSnap.exists()) {
        const messages = docSnap.data().messages;
        setChatHistory(messages);
        setTimeout(() => {
          if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
          }
        }, 100);
      } else {
        setChatHistory([]);
      }
      setLoading(false);
    };

    if (conversationID) {
      fetchConversation();
    } else {
      setChatHistory([]);
      setLoading(false);
    }
  }, [conversationID]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const createNewConversation = async (message) => {
    const conversationRef = doc(collection(db, 'conversations'));
    const timestamp = serverTimestamp();

    await setDoc(conversationRef, {
      userID,
      userName,
      messages: [],
      timestamp,
    });

    await updateDoc(conversationRef, {
      messages: arrayUnion({
        messageID: Date.now().toString(),
        sender: userName,
        content: message,
        timestamp: new Date().toISOString(),
        files: selectedFiles.map(file => ({
          name: file.file.name,
          url: file.url,
          mimeType: file.file.type || 'application/octet-stream', // Save MIME type
        })),
      }),
    });

    return conversationRef.id;
  };

  const fetchCredits = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/credit-balance`, { userID });
      const data = response.data;
      let availableCreditSum = 0;
      data.transactions.forEach(transaction => {
        if (transaction.status === 'available') {
          availableCreditSum += transaction.creditBalance;
        }
      });
      setAvailableCredits(availableCreditSum);
    } catch (error) {
      console.error('Error fetching credit balance:', error);
    } finally {
      setIsCreditsLoading(false); // Set loading to false once the data is fetched
    }
  };
  

  const getChatHistoryForProvider = (chatHistory, selectedProvider) => {
    const formattedHistory = [];
  
    // Check if there are any messages in the chat history
    if (chatHistory.length === 0) {
      return formattedHistory; // No history to return
    }
  
    // Get the very first message sent by the user
    const firstMessage = chatHistory[0];
  
    // Get the last 10 messages from the history
    const lastMessages = chatHistory.slice(-10);
  
    // For OpenAI: Format the history as per OpenAI requirements
    if (selectedProvider === 'OpenAI') {
      lastMessages.forEach(message => {
        if (message.sender === userName) {
          formattedHistory.push({ role: 'user', content: message.content });
        } else if (message.sender === 'AI') {
          formattedHistory.push({ role: 'assistant', content: message.content });
        }
      });
  
      // Add the very first message, if it's not already in the last ten
      const isFirstMessageIncluded = lastMessages.some(
        message => message.content === firstMessage.content
      );
  
      if (!isFirstMessageIncluded && firstMessage.sender === userName) {
        formattedHistory.unshift({ role: 'user', content: firstMessage.content });
      }
    }
  
    // For Google Gemini: Format the history in a different format
    else if (selectedProvider === 'Google Gemini') {
      lastMessages.forEach(message => {
        if (message.sender === userName) {
          formattedHistory.push({ 
            role: 'user', 
            parts: [{ text: message.content }] 
          });
        } else if (message.sender === 'AI') {
          formattedHistory.push({ 
            role: 'model', 
            parts: [{ text: message.content }] 
          });
        }
      });
  
      // Add the very first message, if it's not already in the last ten
      const isFirstMessageIncluded = lastMessages.some(
        message => message.content === firstMessage.content
      );
  
      if (!isFirstMessageIncluded && firstMessage.sender === userName) {
        formattedHistory.unshift({ 
          role: 'user', 
          parts: [{ text: firstMessage.content }] 
        });
      }
    }
  
    return formattedHistory;
  };
  
  
  

  const handleSend = async () => {
    // If trial is active, allow sending without checking credits
    if (trialStatus === 'active') {
      if (trialUsage >= 10) {
        // End trial if usage exceeds limit
        setTrialStatus('completed');
        // You might want to notify the user here that the trial has ended
      }
    } else if (availableCredits <= 0.01) {
      // If not in trial and credits are low, prevent sending
      return;
    }
    
    if (message.trim() === '' && selectedFiles.length === 0) return;
  
    let currentConversationID = conversationID;
  
    if (!currentConversationID) {
      currentConversationID = await createNewConversation(message);
      navigate(`/chat/${currentConversationID}`);
    } else {
      const conversationRef = doc(db, 'conversations', currentConversationID);
  
      await updateDoc(conversationRef, {
        messages: arrayUnion({
          messageID: Date.now().toString(),
          sender: userName,
          content: message,
          timestamp: new Date().toISOString(),
          files: selectedFiles.map(file => ({
            name: file.file.name,
            url: file.url,
            mimeType: file.file.type || 'application/octet-stream', // Save MIME type
          })),
        }),
        timestamp: serverTimestamp(),
      });
    }
  
    // Fetch chat history formatted for the selected provider and model
    const chatHistoryForProvider = getChatHistoryForProvider(chatHistory, selectedProvider, selectedModel);
  
    // Immediately fetch and update chat history after sending the message
    fetchUpdatedChatHistory(currentConversationID);
  
    setChatHistory((prevChatHistory) => [
      ...prevChatHistory,
      { sender: userName, content: message, type: 'user', files: selectedFiles },
    ]);

    // Check and generate title for the conversation after AI response is saved
    setTimeout(() => {
      checkAndGenerateTitle(currentConversationID);
    }, 500); // Delay of 0.5 second to ensure messages are updated
  
    setLoadingAIResponse(true);
    setMessage('');
    setSelectedFiles([]);
    
  
    // Pass the formatted history for the provider to the API
    await getAIResponse(message, currentConversationID, selectedFiles.map(file => file.file), chatHistoryForProvider);
  };
  
  

  const fetchUpdatedChatHistory = async (currentConversationID) => {
    const conversationRef = doc(db, 'conversations', currentConversationID);
    const docSnap = await getDoc(conversationRef);
    if (docSnap.exists()) {
      const messages = docSnap.data().messages;
      setChatHistory(messages);
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }, 100);
  };

  const updateChatHistory = (chunk, isStreaming) => {
    flushSync(() => {
      setChatHistory((prevChatHistory) => {
        const lastMessage = prevChatHistory[prevChatHistory.length - 1];
        if (lastMessage && lastMessage.sender === 'AI' && lastMessage.isStreaming) {
          // Update the existing streaming message
          const updatedMessages = [...prevChatHistory];
          updatedMessages[updatedMessages.length - 1] = {
            ...lastMessage,
            content: lastMessage.content + chunk,
          };
          return updatedMessages;
        } else {
          // Create a new AI message for streaming
          return [
            ...prevChatHistory,
            {
              messageID: Date.now().toString(),
              sender: 'AI',
              content: chunk,
              type: 'ai',
              isStreaming: isStreaming,
            },
          ];
        }
      });
    });
  };
  
  const handleStreamUpdate = (chunk, chunkCount, setLoadingAIResponse) => {
    console.log('Received chunk:', chunk);
    chunkCount++;
  
    if (chunkCount === 1) {
      setLoadingAIResponse(false); // Hide loading indicator after first chunk
    }
  
    updateChatHistory(chunk, true);
  };
  
  const finalizeStreaming = () => {
    flushSync(() => {
      setChatHistory((prevChatHistory) => {
        const updatedMessages = [...prevChatHistory];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage && lastMessage.sender === 'AI' && lastMessage.isStreaming) {
          updatedMessages[updatedMessages.length - 1] = {
            ...lastMessage,
            isStreaming: false,
          };
        }
        return updatedMessages;
      });
    });
  };
  
  const fetchTrialDetailsAndUpdate = async () => {
    try {
      // Fetch user document from Firestore
      const userDocRef = doc(db, 'users', userID);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log("Trial status:", userData.trialStatus); // Log the trial status
        console.log("Trial usage:", userData.trialUsage);   // Log the trial usage


        setTrialStatus(userData.trialStatus || 'completed'); // Update trialStatus state
        setTrialUsage(userData.trialUsage || 0); // Update trialUsage state
  
        // Fetch credits if trial is completed
        if (userData.trialStatus === 'completed') {
          await fetchCredits();
        }
      }
    } catch (error) {
      console.error('Error fetching trial details:', error);
    }
  };
  
  const checkAndGenerateTitle = async (conversationID) => {
    console.log('checkAndGenerateTitle called for conversationID:', conversationID);

    const conversationRef = doc(db, 'conversations', conversationID);
    
    try {
        const docSnap = await getDoc(conversationRef);
        console.log('Fetched conversation document:', docSnap.exists());

        if (docSnap.exists()) {
            const conversationData = docSnap.data();
            console.log('Conversation data:', conversationData);

            // Check if the conversation already has a title
            if (!conversationData.title) {
                console.log('No title found, proceeding to generate title...');
                const messages = conversationData.messages;
                
                // Ensure there is at least one message to generate a title
                if (messages && messages.length >= 1) {
                    console.log('At least one message found in the conversation.');

                    let userMessage = null;

                    // Find the first user message
                    for (let i = 0; i < messages.length; i++) {
                        if (messages[i].sender !== 'AI') {
                            userMessage = messages[i];
                            console.log('First user message found:', userMessage);
                            break; // Stop once we have the first user message
                        }
                    }

                    // If the first user message is found, generate the title
                    if (userMessage) {
                        const prompt = `${userMessage.content}`;
                        console.log('User prompt for title:', prompt);

                        try {
                            console.log('Sending API request to generate title...');
                            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/create-conversation-title`, {
                                prompt,
                            });

                            console.log('API response received:', response.data);
                            const { title } = response.data;

                            // Update title in Firebase
                            if (title) {
                                console.log('Updating Firestore with generated title:', title);
                                await updateDoc(conversationRef, { title });
                                console.log('Firestore successfully updated with new title.');
                            } else {
                                console.error('No title returned from API response.');
                            }
                        } catch (apiError) {
                            console.error('Error generating conversation title:', apiError);
                        }
                    } else {
                        console.warn('Could not find user message. Title generation skipped.');
                    }
                } else {
                    console.warn('Not enough messages to generate a title. Need at least one message.');
                }
            } else {
                console.log('Title already exists for this conversation:', conversationData.title);
            }
        } else {
            console.warn('Conversation document does not exist for conversationID:', conversationID);
        }
    } catch (error) {
        console.error('Error fetching conversation document:', error);
    }
  };



  
  
  
  const getAIResponse = async (message, currentConversationID, files, chatHistoryForProvider) => {
    try {
      console.log('Starting getAIResponse function');
      let aiResponse = '';
      let isStreaming = true;
      let chunkCount = 0;
  
      setLoadingAIResponse(true); // Set loading to true at the start
  
      const gcsUrls = selectedFiles.map(file => file.url);
      const fileUris = selectedFiles.map(file => file.fileUri);
      const mimeTypes = selectedFiles.map(file => file.file.type);
  
      console.log('Selected provider:', selectedProvider);
  
      // Stream logic based on the selected provider
      if (selectedProvider === 'Google Gemini') {
        console.log('Calling getGeminiResponse');
        aiResponse = await getGeminiResponse(
          selectedModel, message, userID, userName, chatHistoryForProvider, fileUris, mimeTypes,
          (chunk) => handleStreamUpdate(chunk, chunkCount, setLoadingAIResponse)
        );
      } else if (selectedProvider === 'OpenAI') {
        console.log('Calling getOpenAIResponse');
        aiResponse = await getOpenAIResponse(
          selectedModel, message, gcsUrls, userID, userName, chatHistoryForProvider,
          (chunk) => handleStreamUpdate(chunk, chunkCount, setLoadingAIResponse)
        );
      }
  
      isStreaming = false;
      finalizeStreaming();
  
      console.log('AI response received:', aiResponse);
  
      const conversationRef = doc(db, 'conversations', currentConversationID);
      const sanitizedResponse = typeof aiResponse === 'string' ? aiResponse : '[Non-text content]';
  
      await updateDoc(conversationRef, {
        messages: arrayUnion({
          messageID: Date.now().toString(),
          sender: 'AI',
          content: sanitizedResponse,
          timestamp: new Date().toISOString(),
        }),
        timestamp: serverTimestamp(),
      });
  
      console.log('Firestore updated');

      setLoadingAIResponse(false); // Ensure loading is false at the end
      console.log('getAIResponse function completed');

      // Check and generate title for the conversation after AI response is saved
      setTimeout(() => {
        checkAndGenerateTitle(currentConversationID);
      }, 1000); // Delay of 1 second to ensure messages are updated


    } catch (error) {
      console.error('Error in getAIResponse:', error);
      setLoadingAIResponse(false);
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        { sender: 'AI', content: 'Failed to fetch AI response', type: 'ai' },
      ]);
    }
  };
  
  

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleInputChange = (e) => {
    const currentMessage = e.target.value;
    setMessage(currentMessage);
    //const lineBreaks = (currentMessage.match(/\n/g) || []).length + 1;
    //setRows(Math.min(6, lineBreaks));
  };

  // Reusable uploadFile function
  const uploadFile = async (fileObj, selectedProvider, userID) => {
    const formData = new FormData();
    formData.append('file', fileObj.file);
    formData.append('provider', selectedProvider); // Pass the selected provider
    formData.append('userID', userID); // Send the userID to the backend

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { gcsUrl, fileUri } = response.data; // Get GCS URL and file URI
      return { ...fileObj, url: gcsUrl, fileUri, uploading: false };
    } catch (error) {
      console.error('Error uploading file:', error);
      return { ...fileObj, uploading: false }; // Retain the existing URL on error
    }
  };

  // handleFileChange: Handles file input changes
  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);

    const newFiles = files.map((file) => {
      const url = URL.createObjectURL(file); // Generate a temporary URL for instant preview
      return { file, url, uploading: true }; // Indicate that the file is uploading
    });

    setSelectedFiles((prev) => [...prev, ...newFiles]);

    const uploadPromises = newFiles.map((fileObj) => uploadFile(fileObj, selectedProvider, userID));

    const uploadedFiles = await Promise.all(uploadPromises);

    setSelectedFiles((prev) =>
      prev.map((file, index) => {
        const uploadedFile = uploadedFiles.find(
          (uploadedFile) => uploadedFile.file.name === file.file.name && uploadedFile.file.size === file.file.size
        );
        return uploadedFile ? { ...file, url: uploadedFile.url, fileUri: uploadedFile.fileUri, uploading: false } : file;
      })
    );
  };

  //Handle Pasting of images from clipboard to upload files
  const handlePaste = async (event) => {
    const items = event.clipboardData.items; // Access clipboard data
    let isImagePasted = false; // Track if an image is being pasted

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      // Check if the item is an image
      if (item.type.startsWith('image/')) {
        isImagePasted = true; // Image found, prevent default paste behavior
        const blob = item.getAsFile(); // Get the file object from the clipboard
        if (blob) {
          const url = URL.createObjectURL(blob); // Create temporary URL for preview

          const fileObj = { file: blob, url, uploading: true }; // File object to upload
          setSelectedFiles((prev) => [...prev, fileObj]);

          // Call uploadFile function to upload the image
          const uploadedFile = await uploadFile(fileObj, selectedProvider, userID);

          // Update the state with the uploaded file
          setSelectedFiles((prev) =>
            prev.map((file) => (file.file === fileObj.file ? uploadedFile : file))
          );
        }
      }
    }

    // Allow default behavior for text if no image was pasted
    if (!isImagePasted) {
      return; // Allow text to be pasted as usual
    } else {
      event.preventDefault(); // Prevent default behavior only when an image is pasted
    }
  };

  

  

  const handleRemoveFile = (index) => {
    const fileToRemove = selectedFiles[index];
  
    // Immediately remove the file from the selectedFiles array
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  
    // Reset the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset file input value
    }
  
    // Now handle the GCS deletion in the background
    if (fileToRemove.url && fileToRemove.url.includes('https://storage.googleapis.com/')) {
      // Perform GCS deletion asynchronously
      axios.post(`${process.env.REACT_APP_API_URL}/api/delete-file-gcs`, { fileUrl: fileToRemove.url })
        .then(() => {
          console.log(`[INFO] File removed from GCS: ${fileToRemove.url}`);
        })
        .catch((error) => {
          console.error('Error removing file from GCS:', error);
          // Optionally handle errors if needed
        });
    }
  };
  
  
  

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code.props.children).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  };

  const CodeBlock = ({ children }) => (
    <Box sx={{ position: 'relative', mb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          backgroundColor: '#2F2F2F',
          borderRadius: '8px 8px 0 0',
          padding: '8px 16px',
          fontSize: '12px',
          color: '#B4B4B4',
          marginBottom: 0,
          cursor: 'pointer',
        }}
        onClick={() => handleCopyCode(children)}
      >
        <Tooltip title={copied ? 'Copied!' : 'Copy code'}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{
                fontSize: '12px',
                color: '#B4B4B4',
                padding: '0px 4px',
                height: '20px',
                width: '20px',
                marginRight: '4px',
                minWidth: 'unset',
              }}
            >
              {copied ? <CheckIcon sx={{ fontSize: '16px' }} /> : <ContentCopyIcon sx={{ fontSize: '16px' }} />}
            </IconButton>
            {copied ? 'Copied!' : 'Copy code'}
          </Box>
        </Tooltip>
      </Box>
      <pre style={{ marginTop: 0 }}>
        <code
          ref={(node) => {
            if (node) {
              hljs.highlightElement(node);
            }
          }}
        >
          {children}
        </code>
      </pre>
    </Box>
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 80px)', paddingTop: '5px', paddingBottom: '5px' }}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center' }}>
        
        {/* Component to show trial status */}
        {trialStatus === 'active' && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#555', // Soft green background
              padding: '12px 16px',
              borderRadius: '15px',
              width: '90%',
              maxWidth: '800px',
              color: '#fff', // Darker green text for contrast
              boxShadow: 'none', // Soft shadow for depth
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              Free Trial : {10-trialUsage} messages left
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#fff', // Use the same color for the link
                cursor: 'pointer',
                textDecoration: 'underline', // Make it look like a link
                '&:hover': {
                  color: '#fff', // Slightly lighter on hover
                },
              }}
              onClick={() => navigate('/usage')}
            >
              View Token & Cost Usage
            </Typography>
          </Box>
        )}

        {/* Custom Low Credits Warning Component */}
        {!isCreditsLoading && trialStatus === 'completed' && availableCredits <= 0.01 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center', // Vertically center content
              justifyContent: 'space-between', // Spread text and button horizontally
              backgroundColor: '#fff3cd', // Same warning background color
              color: '#856404', // Same text color for warning
              padding: '12px 16px', // Padding inside the box
              borderRadius: '10px', // Rounded corners
              width: '90%',
              maxWidth: '800px',
              boxShadow: 'none', // Optional shadow
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center', // Vertically center the icon and text
                flexGrow: 1, // Ensures the text takes up as much space as possible
              }}
            >
              <WarningIcon sx={{ marginRight: '8px' }} /> {/* Replace this with your warning icon */}
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  
                  overflow: 'hidden', // Ensures the text stays in one line
                  textOverflow: 'ellipsis', // Ellipsis for overflow text
                }}
              >
                Your credit balance is ${availableCredits.toFixed(2)}. Please add more credits to continue.
              </Typography>
            </Box>
            <Button
              color="inherit"
              size="small"
              onClick={() => navigate('/add-credits')}
              sx={{
                textTransform: 'none',
                padding: '4px 12px',
                whiteSpace: 'nowrap', // Ensures the button stays in one line
              }}
            >
              Add Credits
            </Button>
          </Box>
        )}

        <Paper
          ref={chatContainerRef}
          sx={{
            flexGrow: 1,
            overflowY: 'auto', // Scroll within Paper
            backgroundColor: '#FFFFFF',
            borderRadius: '10px',
            marginBottom: '10px',
            boxShadow: 'none',
            width: '100%', // Ensure Paper takes full width of its container
          }}
        >
          {/* Inner Box to retain padding for content */}
          <Box
            sx={{
              paddingLeft: '40px', // Apply padding here
              paddingRight: '40px',
              paddingTop: 2,
              paddingBottom: 0,
              maxWidth: '800px', // Limit width of the content
              margin: 'auto', // Center the content within the Paper
            }}
          >
            {loading ? (
              <>
                <Skeleton variant="rectangular" height={20} width={500} sx={{ marginBottom: 2, borderRadius: '10px' }} />
                <Skeleton variant="rectangular" height={20} width={600} sx={{ marginBottom: 2, borderRadius: '10px' }} />
                <Skeleton variant="rectangular" height={20} width={600} sx={{ marginBottom: 2, borderRadius: '10px' }} />
              </>
            ) : (
              <>
                {chatHistory.length === 0 ? (
                  <Typography>No messages yet. Start a conversation!</Typography>
                ) : (
                  chatHistory.map((chat, index) => (
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: chat.sender === userName ? 'flex-end' : 'flex-start', mb: 2 }}>
                      {chat.files?.map((file, i) => (
                        <Box key={i} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          {file.mimeType?.startsWith('image/') ? (
                            <img
                              src={file.url}
                              alt={file.name}
                              style={{
                                width: '120px',
                                height: '120px',
                                borderRadius: '8px',
                                objectFit: 'cover',
                                marginBottom: '8px',
                              }}
                            />
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                borderRadius: '8px',
                                padding: '5px 10px 5px 5px',
                                maxWidth: '300px',
                                wordWrap: 'break-word',
                                border: '1px solid #d3d3d3',
                              }}
                            >
                              <DescriptionOutlinedIcon sx={{ color: '#6c757d', fontSize: 30, mr: 1 }} />
                              <Typography variant="body2" sx={{ textAlign: 'left', verticalAlign: 'top' }}>{file.name}</Typography>
                            </Box>
                          )}
                        </Box>
                      ))}


                      {chat.sender !== userName && (
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', mr: 2 }}>
                          <img src={Logo} alt="Logo" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                        </Box>
                      )}
                      <Typography
                        sx={{
                          backgroundColor: chat.sender === userName ? '#D3D3D3' : 'transparent',
                          color: chat.sender === userName ? '#000' : '#333',
                          padding: '10px 15px',
                          borderRadius: '15px',
                          maxWidth: chat.sender === userName ? '60%' : '100%',
                          textAlign: 'left',
                          boxShadow: 'none',
                          borderBottomRightRadius: chat.sender === userName ? '0px' : '15px',
                          borderBottomLeftRadius: chat.sender !== userName ? '0px' : '15px',
                          marginLeft: chat.sender === userName ? 'auto' : 0,
                          marginRight: chat.sender !== userName ? 'auto' : 0,
                          whiteSpace: 'pre-wrap',
                          wordWrap: 'break-word',
                        }}
                      >
                        <Markdown
                          options={{
                            forceBlock: true,
                            overrides: {
                              pre: {
                                component: CodeBlock,
                              },
                              p: {
                                component: 'div',
                              },
                            },
                          }}
                        >
                          {typeof chat.content === 'string' ? chat.content : '[Non-text content]'}
                        </Markdown>
                      </Typography>
                      {chat.isStreaming && (
                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                          <Lottie options={defaultOptions} height={15} width={60} />
                        </Box>
                      )}
                    </Box>
                  ))
                )}
                {loadingAIResponse && (
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', mr: 2 }}>
                      <img src={Logo} alt="Logo" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '40px' }}>
                      <Lottie options={defaultOptions} height={15} width={60} />
                    </Box>
                  </Box>
                )}
              </>
            )}
            <div ref={inputRef}></div>
          </Box>
        </Paper>
        
        






        






        {/* This is the Box for the input field. Align it with chat history */}
        <Box sx={{ display: 'flex', maxWidth: '800px', width: '100%', alignItems: 'flex-end', padding: 0, position: 'relative', margin: 'auto' }}>
          <input
            accept={selectedProvider === 'OpenAI' ? "image/*" : "image/*,video/*,application/pdf"} // Change accepted file types based on provider
            type="file"
            id="file-upload"
            multiple
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <label htmlFor="file-upload">
            <IconButton
              component="span"
              sx={{
                position: 'absolute',
                bottom: '20px',
                left: '10px',
                color: '#000000',
                zIndex: 1,
                visibility: selectedProvider === 'OpenAI' && selectedModel === 'gpt-4o-mini' ? 'hidden' : 'visible',
              }}
            >
              {selectedProvider === 'OpenAI' ? (
                <AddPhotoAlternateOutlinedIcon />
              ) : (
                <AttachFileIcon />
              )}
            </IconButton>
          </label>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            minRows={1}    // Set the minimum number of rows
            maxRows={6}    // Set the maximum number of rows
            value={message}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            placeholder="Enter your prompt here"
            sx={{
              flexGrow: 1,
              backgroundColor: '#F0F0F0',
              borderRadius: '30px',
              paddingLeft: '50px',
              paddingRight: '60px',
              display: 'flex',
              alignItems: 'flex-end',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
              position: 'relative',
              marginBottom: '10px',
            }}
            inputProps={{
              style: {
                color: 'inherit',
              },
              sx: {
                '::placeholder': {
                  color: 'grey',
                  opacity: 1,
                },
              },
            }}
          />
          <IconButton
            color="default"
            onClick={handleSend}
            disabled={(trialStatus === 'completed' && isCreditsLoading) || (trialStatus === 'completed' && availableCredits <= 0.01) || loadingAIResponse} // Only disable if trial is completed and credits are low
            sx={{
              position: 'absolute',
              bottom: '20px',
              right: '10px',
              color: '#000000',
            }}
          >
            <SendIcon />
          </IconButton>
        </Box>
  
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            mt: 1, 
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            maxWidth: '800px', 
          }}>
          {selectedFiles.map((file, index) => (
            <Box key={index} sx={{ position: 'relative', mr: 2, mb: 1 }}>
              {file.uploading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                    zIndex: 2,
                  }}
                />
              )}
              {file.file.type.startsWith('image/') ? (
                <img
                  src={file.url}
                  alt={file.file.name}
                  style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '8px',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '8px',
                    padding: '5px 10px 5px 5px',
                    maxWidth: '300px',
                    maxHeight: '60px',
                    wordWrap: 'break-word',
                    border: '1px solid #d3d3d3',
                  }}
                >
                  <DescriptionOutlinedIcon sx={{ color: '#6c757d', fontSize: 30, mr: 0.5 }} />
                  <Tooltip title={file.file.name}>
                    <Typography variant="caption" sx={{ textAlign: 'left' }}>
                      {file.file.name.length > 15 ? `${file.file.name.slice(0, 15)}...` : file.file.name}
                    </Typography>
                  </Tooltip>
                </Box>
              )}
              <IconButton
                size="small"
                onClick={() => handleRemoveFile(index)}
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                  zIndex: 2,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Keep background color on hover
                  },
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
  
  
  
  
};

export default Chat;
