import React, { useState, useEffect } from 'react';
import { auth, provider } from './firebase';
import { signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Chat from './components/Chat';
import Usage from './components/Usage';
import TransactionHistory from './components/TransactionHistory';
import Homepage from './components/Homepage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state to track auth status
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('Google Gemini');
  const [selectedModel, setSelectedModel] = useState('gemini-1.5-flash');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Set loading to false after Firebase resolves the auth status

      if (!currentUser && location.pathname !== '/') {
        setSidebarOpen(false); // Close sidebar on logout
        navigate('/'); // If not authenticated, always navigate to root
      } else if (currentUser && location.pathname === '/') {
        navigate('/chat'); // Redirect to /chat if the user is authenticated and on root
      }
    });

    return () => unsubscribe();
  }, [navigate, location.pathname]);

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
      })
      .catch((error) => {
        console.error('Error during sign-in:', error);
      });
  };

  // Create a custom theme with Hanken Grotesk
  const theme = createTheme({
    typography: {
      fontFamily: "'Hanken Grotesk', sans-serif",
    },
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Prevent rendering Routes until authentication status is known
  if (loading) {
    return <div>Loading...</div>; // Show a loading state until auth status is resolved
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Sidebar open={sidebarOpen} toggleSidebar={toggleSidebar} userID={user?.uid} />
        <div style={{ marginLeft: sidebarOpen ? '250px' : '0px', transition: 'margin-left 0.3s' }}>
          <Header
            user={user}
            toggleSidebar={toggleSidebar}
            sidebarOpen={sidebarOpen}
            onProviderChange={setSelectedProvider}
            onModelChange={setSelectedModel}
          />
          <Routes>
            {/* Chat route with conversation ID */}
            <Route
              path="/chat/:conversationID"
              element={
                user ? (
                  <Chat
                    selectedProvider={selectedProvider}
                    selectedModel={selectedModel}
                    userID={user.uid}
                    userName={user.displayName}
                  />
                ) : (
                  <Navigate to="/" /> // Navigate to homepage if not signed in
                )
              }
            />

            {/* Chat route without conversation ID */}
            <Route
              path="/chat"
              element={
                user ? (
                  <Chat
                    selectedProvider={selectedProvider}
                    selectedModel={selectedModel}
                    userID={user.uid}
                    userName={user.displayName}
                  />
                ) : (
                  <Navigate to="/" /> // Navigate to homepage if not signed in
                )
              }
            />

            {/* Usage route */}
            <Route
              path="/usage"
              element={
                user ? (
                  <Usage userID={user.uid} /> // Render the Usage component for the /usage route
                ) : (
                  <Navigate to="/" /> // Navigate to homepage if not signed in
                )
              }
            />

            {/* Transaction History route */}
            <Route
              path="/transactions"
              element={
                user ? (
                  <TransactionHistory userID={user.uid} /> // Render Transaction History for the /transactions route
                ) : (
                  <Navigate to="/" /> // Navigate to homepage if not signed in
                )
              }
            />

            {/* Default route for non-logged-in users */}
            <Route
              path="/"
              element={<Homepage signInWithGoogle={signInWithGoogle} />} // Use HomePage component here
            />
          </Routes>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
