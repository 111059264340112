import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LinearProgress, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CreditBalance = ({ userID }) => {
  const [promotionalCredits, setPromotionalCredits] = useState(0);
  const [purchasedCredits, setPurchasedCredits] = useState(0);
  const [availableCredits, setAvailableCredits] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/credit-balance`, { userID });
        const data = response.data;

        let totalCreditSum = 0;
        let availableCreditSum = 0;

        data.transactions.forEach(transaction => {
          if (transaction.status === 'available') {
            totalCreditSum += transaction.amount;
            availableCreditSum += transaction.creditBalance;
          }
        });

        setPromotionalCredits(data.promotionalCredits);
        setPurchasedCredits(data.purchasedCredits);
        setAvailableCredits(availableCreditSum);
        setTotalCredits(totalCreditSum);
      } catch (error) {
        console.error('Error fetching credit balance:', error);
      }
    };

    fetchCredits();
  }, [userID]);

  const getPercentage = () => {
    if (totalCredits === 0) return 0;
    return (availableCredits / totalCredits) * 100;
  };

  const handleViewDetails = () => {
    navigate('/transactions');
  };

  return (
    <Box 
      sx={{ 
        padding: 4, 
        maxWidth: '100%',
        textAlign: 'left',
        backgroundColor: '#ffffff', // Change to white for modern design
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
        borderRadius: '12px', // Rounded corners for a modern look
        marginBottom: 3
      }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#333' }}> {/* Improved color contrast */}
          Credit Balance
        </Typography>
        <Button
          onClick={handleViewDetails}
          sx={{ 
            textDecoration: 'underline', 
            fontSize: '12px', 
            color: '#007bff', 
            padding: 0, 
            '&:hover': { color: '#0056b3' } // Add hover effect for better interaction
          }}
        >
          View Details
        </Button>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <LinearProgress 
          variant="determinate" 
          value={getPercentage()} 
          sx={{ 
            flexGrow: 1, 
            height: 10,  // Slightly increase the height for a more robust look
            borderRadius: 5, 
            backgroundColor: '#e0e0e0', 
            marginRight: 2 
          }}
        />
        <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px', whiteSpace: 'nowrap', color: '#333' }}>
          ${availableCredits.toFixed(2)} / ${totalCredits.toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
};

export default CreditBalance;
