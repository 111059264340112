import React, { useState } from 'react';
import { AppBar, Toolbar, Avatar, Box, Menu, MenuItem, IconButton, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { signOut } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { auth } from '../firebase';

const Header = ({ user, toggleSidebar, sidebarOpen, onProviderChange, onModelChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [providerAnchorEl, setProviderAnchorEl] = useState(null);
  const [modelAnchorEl, setModelAnchorEl] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState('Google Gemini');
  const [selectedModel, setSelectedModel] = useState('gemini-1.5-flash');
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation hook

  // Provider to Models Mapping (Dynamic, no hardcoding)
  const providerModelMap = {
    OpenAI: ['gpt-4o-mini', 'gpt-4o'],
    'Google Gemini': ['gemini-1.5-flash', 'gemini-1.5-pro'],
    // Add more providers and models here as needed
  };

  const handleSignOut = () => {
    signOut(auth).catch((error) => console.error('Error signing out:', error));
    handleClose();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProviderClick = (event) => {
    setProviderAnchorEl(event.currentTarget);
  };

  const handleProviderClose = () => {
    setProviderAnchorEl(null);
  };

  const handleProviderSelect = (provider) => {
    setSelectedProvider(provider);

    // Automatically select the first model of the newly selected provider dynamically
    const modelsForProvider = providerModelMap[provider];
    if (modelsForProvider && modelsForProvider.length > 0) {
      const firstModel = modelsForProvider[0];
      setSelectedModel(firstModel);
      onProviderChange(provider); // Pass the selected provider to the parent component
      onModelChange(firstModel); // Pass the first model to the parent component
    }

    handleProviderClose();
  };

  const handleModelClick = (event) => {
    setModelAnchorEl(event.currentTarget);
  };

  const handleModelClose = () => {
    setModelAnchorEl(null);
  };

  const handleModelSelect = (model) => {
    setSelectedModel(model);
    onModelChange(model); // Pass the selected model to the parent component
    handleModelClose();
  };

  const handleUsageClick = () => {
    navigate('/usage'); // Navigate to the usage page
    handleClose();
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {user && !sidebarOpen && (
            <IconButton onClick={toggleSidebar} sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
          )}
          {user && location.pathname.includes('/chat') && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                aria-controls="provider-menu"
                aria-haspopup="true"
                onClick={handleProviderClick}
                endIcon={<ArrowDropDown />}
                sx={{
                  textTransform: 'none',
                  color: '#7D7D7D',
                  padding: '6px 12px',
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  fontSize: '16px',
                  minWidth: 0,
                  mr: 2,
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                {selectedProvider}
              </Button>
              <Menu
                id="provider-menu"
                anchorEl={providerAnchorEl}
                open={Boolean(providerAnchorEl)}
                onClose={handleProviderClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={{ mt: 1 }}
              >
                {Object.keys(providerModelMap).map((provider) => (
                  <MenuItem
                    key={provider}
                    selected={provider === selectedProvider}
                    onClick={() => handleProviderSelect(provider)}
                  >
                    {provider}
                  </MenuItem>
                ))}
              </Menu>

              <Button
                aria-controls="model-menu"
                aria-haspopup="true"
                onClick={handleModelClick}
                endIcon={<ArrowDropDown />}
                sx={{
                  textTransform: 'none',
                  color: '#7D7D7D',
                  padding: '6px 12px',
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  fontSize: '16px',
                  minWidth: 0,
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                {selectedModel}
              </Button>
              <Menu
                id="model-menu"
                anchorEl={modelAnchorEl}
                open={Boolean(modelAnchorEl)}
                onClose={handleModelClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={{ mt: 1 }}
              >
                {(providerModelMap[selectedProvider] || []).map((model) => (
                  <MenuItem
                    key={model}
                    selected={model === selectedModel}
                    onClick={() => handleModelSelect(model)}
                  >
                    {model}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
          {user && location.pathname === '/usage' && (
            <Typography variant="h6" component="div" sx={{ fontFamily: 'Hanken Grotesk, sans-serif', fontWeight: 'bold' }}>
              Usage Details
            </Typography>
          )}
          {!user && (
            <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Hanken Grotesk, sans-serif' }}>
              <img src="/logo.png" alt="Aggrefy" style={{ height: '30px', marginRight: '10px' }} />
              Platformz.ai
            </Typography>
          )}
        </Box>
        {user && (
          <Box display="flex" alignItems="center">
            <Avatar
              alt={user.displayName}
              src={user.photoURL}
              onClick={handleMenu}
              sx={{ cursor: 'pointer', width: 32, height: 32 }}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleUsageClick}>Usage</MenuItem>
              <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;