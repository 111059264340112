import React from 'react';
import Button from '@mui/material/Button';

const HomePage = ({ signInWithGoogle }) => {
  const heroStyle = {
    textAlign: 'center',
    color: '#000',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '80px 10px',
    overflow: 'hidden',
    position: 'relative',
  };

  const headingStyle = {
    fontSize: '2.4rem',
    fontFamily: "'Hanken Grotesk', sans-serif",
    marginBottom: '10px',
    marginTop:'20px',
    letterSpacing: '1.5px',
    zIndex: '1',
  };

  const subTextStyle = {
    fontSize: '1rem',
    marginBottom: '10px',
    color: '#555',
    zIndex: '1',
  };

  const buttonStyle = {
    backgroundColor: '#000',
    color: '#fff',
    fontSize: '1rem',
    padding: '8px 25px',
    borderRadius: '30px',
    zIndex: '1',
    marginBottom: '30px',
    marginTop: '20px',
    border: 'none',
    textTransform: 'none',
  };

  const benefitsStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '1100px',
    gap: '15px',
    zIndex: '1',
    marginTop: '10px',
  };

  const benefitCardStyle = {
    backgroundColor: '#f5f5f5',
    padding: '15px 15px 15px 15px',
    borderRadius: '10px',
    maxWidthwidth: '100px', // This will only apply for larger screens
    color: '#000',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'none',
    border: '1px solid #ddd',
  };

  const benefitTitleStyle = {
    fontSize: '1.1rem',
    color: '#000',
    marginBottom: '8px',
    textAlign: 'center',
  };

  const benefitTextStyle = {
    fontSize: '0.9rem',
    color: '#555',
    textAlign: 'center',
  };

  const benefitCardHoverStyle = {
    backgroundColor: '#e0e0e0',
    transform: 'translateY(-10px)',
  };

  return (
    <div style={heroStyle}>
      {/* Hero Section */}
      <section>
        <h1 style={headingStyle}>One platform for all AI models</h1>
        <p style={subTextStyle}>Access premium AI models from Google, OpenAI & more, with simple pay-as-you-go pricing</p>
        <Button
          variant="contained"
          color="primary"
          style={buttonStyle}
          onClick={signInWithGoogle}
        >
          Try For Free
        </Button>
      </section>

      {/* Key Benefits Section */}
      <section className="benefits-section" style={benefitsStyle}>
        <div
          style={benefitCardStyle}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = benefitCardHoverStyle.backgroundColor;
            e.currentTarget.style.transform = benefitCardHoverStyle.transform;
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = benefitCardStyle.backgroundColor;
            e.currentTarget.style.transform = 'translateY(0)';
          }}
        >
          <h3 style={benefitTitleStyle}>It's cost effective</h3>
          <p style={benefitTextStyle}>No monthly subscriptions. Pay only for what you use. </p>
        </div>
        <div
          style={benefitCardStyle}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = benefitCardHoverStyle.backgroundColor;
            e.currentTarget.style.transform = benefitCardHoverStyle.transform;
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = benefitCardStyle.backgroundColor;
            e.currentTarget.style.transform = 'translateY(0)';
          }}
        >
          <h3 style={benefitTitleStyle}>It's flexible</h3>
          <p style={benefitTextStyle}>Switch between any model, any provider, anytime</p>
        </div>
        <div
          style={benefitCardStyle}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = benefitCardHoverStyle.backgroundColor;
            e.currentTarget.style.transform = benefitCardHoverStyle.transform;
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = benefitCardStyle.backgroundColor;
            e.currentTarget.style.transform = 'translateY(0)';
          }}
        >
          <h3 style={benefitTitleStyle}>It's transparent</h3>
          <p style={benefitTextStyle}>Easily track your token usage and credits in real-time.</p>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
