import React, { useEffect, useState, useRef } from 'react';
import { Box, IconButton, List, ListItem, ListItemText, Typography, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, orderBy, limit, onSnapshot, startAfter } from 'firebase/firestore';
import dayjs from 'dayjs';
import { updateDoc, doc } from 'firebase/firestore';

const Sidebar = ({ open, toggleSidebar, userID }) => {
  const [conversations, setConversations] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const navigate = useNavigate();
  const [editingConversationId, setEditingConversationId] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  const [activeMenu, setActiveMenu] = useState(null);

  const menuRef = useRef(null);

  useEffect(() => {
    if (userID) {
      const conversationsRef = collection(db, 'conversations');
      const q = query(
        conversationsRef,
        where('userID', '==', userID),
        orderBy('timestamp', 'desc'),
        limit(15)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const conversationList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        setConversations(conversationList);
      });

      return () => unsubscribe();
    }
  }, [userID]);

  const loadMoreConversations = () => {
    if (loadingMore || !lastDoc) return;
    setLoadingMore(true);

    const conversationsRef = collection(db, 'conversations');
    const q = query(
      conversationsRef,
      where('userID', '==', userID),
      orderBy('timestamp', 'desc'),
      startAfter(lastDoc),
      limit(10)
    );

    onSnapshot(q, (snapshot) => {
      const newConversations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      setConversations((prev) => [...prev, ...newConversations]);
      setLoadingMore(false);
    });
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 10) {
      loadMoreConversations();
    }
  };

  const handleConversationClick = (conversationID) => {
    navigate(`/chat/${conversationID}`);
  };

  const handleNewChat = () => {
    navigate('/chat');
  };

  const handleRenameClick = (conversationId, currentTitle) => {
    setEditingConversationId(conversationId);
    setNewTitle(currentTitle);
  };

  const handleRenameSubmit = async (conversationId) => {
    if (newTitle.trim()) {
      await updateDoc(doc(db, 'conversations', conversationId), { title: newTitle });
    }
    setEditingConversationId(null);
  };

  const handleArchiveClick = async (conversationId) => {
    if (window.confirm('Are you sure you want to archive this conversation?')) {
      await updateDoc(doc(db, 'conversations', conversationId), { archived: true });
      setConversations((prev) => prev.filter((c) => c.id !== conversationId));
    }
  };

  const handleMenuClick = (e, conversationId) => {
    e.stopPropagation();
    setActiveMenu(conversationId);
  };

  const handleClickOutsideMenu = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setActiveMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenu);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, []);

  const groupConversations = () => {
    const today = [];
    const yesterday = [];
    const last7Days = [];
    const earlier = [];

    conversations.forEach((conversation) => {
      const lastMessageTimestamp = dayjs(conversation.timestamp?.toDate());

      if (lastMessageTimestamp.isSame(dayjs(), 'day')) {
        today.push(conversation);
      } else if (lastMessageTimestamp.isSame(dayjs().subtract(1, 'day'), 'day')) {
        yesterday.push(conversation);
      } else if (lastMessageTimestamp.isAfter(dayjs().subtract(7, 'day'))) {
        last7Days.push(conversation);
      } else {
        earlier.push(conversation);
      }
    });

    return { today, yesterday, last7Days, earlier };
  };

  const { today, yesterday, last7Days, earlier } = groupConversations();

  const renderConversations = (group, label) => (
    <>
      {group.length > 0 && (
        <>
          <Typography
            sx={{
              fontSize: '0.75rem',
              color: 'black',
              marginTop: 2,
              marginBottom: 0.1,
              fontWeight: '600',
              textAlign: 'left',
              paddingLeft: 3,
            }}
          >
            {label}
          </Typography>
          <List sx={{ paddingX: 1 }}>
            {group.map((conversation) => (
              <ListItem
                button
                key={conversation.id}
                onClick={() => handleConversationClick(conversation.id)}
                sx={{
                  '&:hover': { backgroundColor: '#ececec' }, // Change background on hover
                  backgroundColor: window.location.pathname.includes(conversation.id) ? '#ececec' : 'inherit',
                  borderRadius: '10px',
                  marginY: '1px',
                  paddingY: '5px',
                  position: 'relative',
                }}
              >
                {editingConversationId === conversation.id ? (
                  <input
                    type="text"
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                    onBlur={() => handleRenameSubmit(conversation.id)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleRenameSubmit(conversation.id);
                    }}
                  />
                ) : (
                  <ListItemText
                    primaryTypographyProps={{ noWrap: true, fontSize: '0.875rem' }}
                    secondaryTypographyProps={{ fontSize: '0.75rem' }}
                    primary={conversation.title || conversation.messages[conversation.messages.length - 1]?.content || 'New Conversation'}
                  />
                )}
                
                <IconButton
                  size="small"
                  onClick={(e) => handleMenuClick(e, conversation.id)}
                  sx={{
                    padding: '4px',
                    marginLeft: 'auto',
                    borderRadius: '50%',  // Ensure it's a circle
                    display: window.location.pathname.includes(conversation.id) ? 'block' : 'none', // Always show when selected
                    '&:hover': {
                      display: 'block',
                    },
                    '&.MuiListItem-root:hover &': { // Show on ListItem hover
                      display: 'block',
                    },
                  }}
                >
                  <MoreVertIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
              
                {activeMenu === conversation.id && (
                  <Box
                    ref={menuRef}
                    className="menu-options"
                    sx={{
                      position: 'absolute',
                      top: '100%',
                      right: '0',
                      zIndex: 10,
                      backgroundColor: 'white',
                      boxShadow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: '8px',  // Rounded rectangle
                      padding: '4px', // Padding to make the menu look cleaner
                      width: 'auto',  // Optional: Adjust width as per your design
                    }}
                  >
                    <Button
                      onClick={() => handleRenameClick(conversation.id, conversation.title)}
                      sx={{
                        textAlign: 'left',
                        color: 'black',  // Black text color
                        textTransform: 'none',  // Normal case text
                        paddingY: '8px',  // Adjust padding to make the buttons visually appealing
                        borderRadius: '8px',  // Rounded corners for buttons
                        '&:hover': {
                          backgroundColor: '#e0e0e0',  // Grey background on hover
                        },
                      }}
                    >
                      Rename
                    </Button>
                    <Button
                      onClick={() => handleArchiveClick(conversation.id)}
                      sx={{
                        textAlign: 'left',
                        color: 'black',  // Black text color
                        textTransform: 'none',  // Normal case text
                        paddingY: '8px',  // Adjust padding to make the buttons visually appealing
                        borderRadius: '8px',  // Rounded corners for buttons
                        '&:hover': {
                          backgroundColor: '#e0e0e0',  // Grey background on hover
                        },
                      }}
                    >
                      Archive
                    </Button>
                  </Box>                
                )}
              </ListItem>            
            ))}
          </List>
        </>
      )}
    </>
  );

  return (
    <Box
      sx={{
        width: open ? '250px' : '0px',
        transition: 'width 0.3s',
        overflow: 'hidden',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: 1000,
        paddingTop: '10px',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#B0B0B0',
          borderRadius: '10px',
        },
      }}
      onScroll={handleScroll}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pl: 2, pr: 2 }}>
        <IconButton onClick={toggleSidebar}>
          <MenuIcon/>
        </IconButton>
      </Box>
      {open && (
        <>
          <Box sx={{ paddingLeft: 2, paddingTop: 1 }}>
            <Button
              onClick={handleNewChat}
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#E3E3E3',
                borderRadius: '20px',
                paddingY: '5px',
                paddingLeft: '10px',
                paddingRight: '20px',
                width: 'max-content',
                color: 'black',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#D3D3D3',
                },
              }}
            >
              <AddIcon sx={{ marginRight: 1 }} />
              <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>New chat</Typography>
            </Button>
          </Box>
          {renderConversations(today, 'Today')}
          {renderConversations(yesterday, 'Yesterday')}
          {renderConversations(last7Days, 'Last 7 Days')}
          {renderConversations(earlier, 'Earlier')}
        </>
      )}
    </Box>
  );
};

export default Sidebar;
